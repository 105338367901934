<!-- Desktop -->
<div class="content" role="main">
  <div class="app-container">
    <img class="app-back-logo" src="assets/img/back_logo.svg" alt="Under Logo">
    <img class="app-logo" src="assets/img/logo.svg" alt="Logo">    
    <div class="app-left-side">
      <h1 class="left-tittle">Selecciona un país:</h1>
      <div class="app-buttons-container">
        <div *ngFor="let pais of paises" class="app-select-item" (click)="redireccionarPais(pais.codigo)">
          <img class="app-select-icon" [src]="'assets/img/country-' + pais.codigo + '.svg'" [alt]="pais.nombre">
          <br>
          <span class="app-select-text">{{ pais.nombre }}</span> <span class="arrow-left"><i class="arrow-country"></i></span>
        </div>
      </div>
    </div>
    <div class="app-right-side">

      <div class="card-container">
        <div class="card" [class.expanded]="isExpand(0)">
          <div class="image-container" [class.image-container-expanded]="isExpand(0)">
            <!-- Imagen difuminada -->
            <img src="assets/img/jobs.webp" alt="Imagen" class="blurred-image">
            
          </div>
          <div class="content-card">
            <h2 class="card-title">Publica vacantes Ilimitadas</h2>
            <!-- Texto -->
            <div class="text" [class.expanded]="isExpand(0)">
              <br>
              <strong>Encuentra a tu candidato:</strong>
              <br>
              Te conectamos con candidatos cada vez más preparados para tus posiciones operativas, bilingues, administrativas y directivas. 
              <br>
              <span class="highlight force">¡Publica tus vacantes, Ilimitadamente!</span>
              <p><Strong>Encuentra herramientas de reclutamiento y seleccion de personal como:</Strong></p>
              <ul>
                <li>IA para el reclutamiento automatizado</li>
                <li>Entrevistas en línea automatizadas</li>
                <li>Consulta de antecedentes de candidatos en línea</li>
                <li>Aplicación de pruebas en línea</li>
                <li>Capacitación para líderes</li>
              </ul>
            </div>
            <div class="footer">
              <i class="arrow" [class.up]="isExpand(0)" (click)="toggleExpansion(0)"></i>
              <!-- Botón de ver más/menos -->
              <button (click)="toggleExpansion(0)">
                {{ isExpand(0) ? 'Ver menos' : 'Ver más' }}
                
              </button>
            </div>
          </div>
        </div>
        <div class="card" [class.expanded]="isExpand(1)">
          <div class="image-container" [class.image-container-expanded]="isExpand(1)">
            <!-- Imagen difuminada -->
            <img src="assets/img/interviews.webp" alt="Imagen" class="blurred-image">
            
          </div>
          <div class="content-card">
            <h2 class="card-title">Entrenamiento para el trabajo</h2>
            <!-- Texto -->
            <div class="text" [class.expanded]="isExpand(1)">
              <br>
              <strong>Entrena para el empleo:</strong>
              <br>
              Practicando tus entrevistas laborales en línea gratis en español o ingles con RIO, nuestra Inteligencia Artificial; incrementa tus posibilidades de encontrar el empleo que mereces.
              <span class="highlight">Regístrate ahora</span><br>
              <p><Strong>Además encuentra:</Strong></p>
              <ul>
                <li>Talleres personalizados con psicologos expertos</li>
                <li>Capacitación en línea gratuita</li>
                <li>Ferias de empleo virtuales y presenciales</li>
                <li>Consejos diarios para tu vida laboral</li>
                <li>Nuevas ofertas laborales, todos los días</li>
              </ul>
            </div>
            <div class="footer">
              <i class="arrow" [class.up]="isExpand(1)" (click)="toggleExpansion(1)"></i>
              <!-- Botón de ver más/menos -->
              <button (click)="toggleExpansion(1)">
                {{ isExpand(1) ? 'Ver menos' : 'Ver más' }}
                
              </button>
            </div>
          </div>
        </div>
        <div class="card" [class.expanded]="isExpand(2)">
          <div class="image-container" [class.image-container-expanded]="isExpand(2)">
            <!-- Imagen difuminada -->
            <img src="assets/img/head_hunter.webp" alt="Imagen" class="blurred-image">
            
          </div>
          <div class="content-card">
            <h2 class="card-title">Seleccionamos tu equipo / Head Hunter</h2>
            <!-- Texto -->
            <div class="text" [class.expanded]="isExpand(2)">
              <br>
              <strong>Head Hunter:</strong>
              <br>
              Encontramos de manera rapida y personalizada a tus candidatos en toda Latinoamerica. No importa en donde estes, nuestros expertos en selección encontrarán por ti el candidato ideal.
              <br>
              <ul>
                <li>Reclutamos</li>
                <li>Aplicamos pruebas psicotecnicas</li>
                <li>Programamos entrevistas</li>
                <li>Te acompañamos en todo el proceso de selección hasta encontrar el candidato ideal</li>
              </ul>
              <p><Strong>Aplica para todas las posiciones:</Strong></p>
              <span class="highlight force">Administrativo, Comercial, directivo, bilingue, operativo y directivo.</span>
            </div>
            <div class="footer">
              <i class="arrow" [class.up]="isExpand(2)" (click)="toggleExpansion(2)"></i>
              <!-- Botón de ver más/menos -->
              <button (click)="toggleExpansion(2)">
                {{ isExpand(2) ? 'Ver menos' : 'Ver más' }}
                
              </button>
            </div>
          </div>
        </div>
      </div>

      <h1 class="title-footer"><span class="purple">Conexiones únicas </span><span class="orange">de empleabilidad</span></h1>
  
  
    </div>
  </div>


</div>

<!-- Mobile -->

<div class="app-container-mobile">
  <img class="app-logo" src="assets/img/logo.svg" alt="Logo">
  <div class="app-left-side">
    <h2 class="app-slogan">
      <br><p><span class="sub">Selecciona un país:</span></p></h2>
      <div class="app-buttons-container">
        <div *ngFor="let pais of paises" class="app-select-item" (click)="redireccionarPais(pais.codigo)">
          <img class="app-select-icon" [src]="'assets/img/country-' + pais.codigo + '.svg'" [alt]="pais.nombre">
          <br>
          <span class="app-select-text">{{ pais.nombre }}</span> <span class="arrow-left"><i class="arrow-country"></i></span>
        </div>
      </div>
      <h3 class="title-footer"><span class="purple">Conexiones únicas </span><span class="orange"> de empleabilidad</span></h3>
  </div>
  <div class="app-right-side">
    <div class="app-buttons-container">
      <div class="app-select-item extended" (click)="toggleExpansion(3)">
        <div class="mobile-card">
          <img class="app-select-icon" [src]="'assets/img/jobs.webp'" alt="Jobs">
          <br>
          <span class="app-select-text inline-size">Publica vacantes Ilimitadas</span> <span class="arrow-left"><i class="arrow-mobile" [class.down]="isExpand(3)"></i></span>
        </div>
        <div class="text" [class.expanded]="isExpand(3)">
          <br>
          <strong>Encuentra a tu candidato:</strong>
          <br>
          Te conectamos con candidatos cada vez más preparados para tus posiciones operativas, bilingues, administrativas y directivas. 
          <br>
          <span class="highlight force">¡Publica tus vacantes, Ilimitadamente!</span>
          <p><Strong>Encuentra herramientas de reclutamiento y seleccion de personal como:</Strong></p>
          <ul>
            <li>IA para el reclutamiento automatizado</li>
            <li>Entrevistas en línea automatizadas</li>
            <li>Consulta de antecedentes de candidatos en línea</li>
            <li>Aplicación de pruebas en línea</li>
            <li>Capacitación para líderes</li>
          </ul>
        </div>
      </div>
      <div class="app-select-item extended" (click)="toggleExpansion(4)">
        <div class="mobile-card">
          <img class="app-select-icon" [src]="'assets/img/interviews.webp'" alt="Interviews">
          <br>
          <span class="app-select-text inline-size">Entrenamiento para el trabajo</span> <span class="arrow-left"><i class="arrow-mobile" [class.down]="isExpand(4)"></i></span>
        </div>
        <div class="text" [class.expanded]="isExpand(4)">
          <br>
          <strong>Entrena para el empleo:</strong>
          <br>
          Practicando tus entrevistas laborales en línea gratis en español o ingles con RIO, nuestra Inteligencia Artificial; incrementa tus posibilidades de encontrar el empleo que mereces.
          <span class="highlight">Regístrate ahora</span><br>
          <p><Strong>Además encuentra:</Strong></p>
          <ul>
            <li>Talleres personalizados con psicologos expertos</li>
            <li>Capacitación en línea gratuita</li>
            <li>Ferias de empleo virtuales y presenciales</li>
            <li>Consejos diarios para tu vida laboral</li>
            <li>Nuevas ofertas laborales, todos los días</li>
          </ul>
        </div> 
      </div>
      <div class="app-select-item extended" (click)="toggleExpansion(5)">
        <div class="mobile-card">
          <img class="app-select-icon" [src]="'assets/img/head_hunter.webp'" alt="Hunter">
          <br>
          <span class="app-select-text inline-size">Seleccionamos tu equipo / Head Hunter</span> <span class="arrow-left"><i class="arrow-mobile" [class.down]="isExpand(5)"></i></span>
        </div>
        <div class="text" [class.expanded]="isExpand(5)">
          <br>
          <strong>Head Hunter:</strong>
          <br>
          Encontramos de manera rapida y personalizada a tus candidatos en toda Latinoamerica. No importa en donde estes, nuestros expertos en selección encontrarán por ti el candidato ideal.
          <br>
          <ul>
            <li>Reclutamos</li>
            <li>Aplicamos pruebas psicotecnicas</li>
            <li>Programamos entrevistas</li>
            <li>Te acompañamos en todo el proceso de selección hasta encontrar el candidato ideal</li>
          </ul>
          <p><Strong>Aplica para todas las posiciones:</Strong></p>
          <span class="highlight force">Administrativo, Comercial, directivo, bilingue, operativo y directivo.</span>
        </div>
      </div>
      
    </div>
  </div>
</div>




<router-outlet></router-outlet>
