import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  paises = [
    { codigo: 'co', nombre: 'Colombia' },
    // { codigo: 'cl', nombre: 'Chile' },
    { codigo: 'pe', nombre: 'Perú' },
    { codigo: 'ec', nombre: 'Ecuador' },
    { codigo: 'mx', nombre: 'México' },
    // { codigo: 'pa', nombre: 'Panamá' },
    // Agrega más países según sea necesario
  ];

  isExpanded = [
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  constructor(private router: Router) {}

  redireccionarPais(codigoPais: string) {
    // Construye la URL de redirección
    const urlRedireccion = `https://${codigoPais}.okempleos.com`;

    // Realiza la redirección
    window.location.href = urlRedireccion;
  }

  toggleExpansion(i: number) {
    this.isExpanded[i] = !this.isExpanded[i];
  }

  isExpand(i: number){
    return this.isExpanded[i];
  }
  
}
